<template>
  <div class="question">
    <div class="top">
      <h1 class="question-title">更新日志</h1>
      <div class="end">
        <div class="end-box">
          <div class="meun">
            <el-menu
              default-active="0"
              background-color="#fff"
              text-color="#333"
              active-text-color="#fff"
            >
              <el-menu-item
                @click="menuItemClick(index)"
                v-for="(list, index) in leftList"
                :key="index"
                :index="index.toString()"
              >
                <span slot="title">{{ list.label }}</span>
              </el-menu-item>
            </el-menu>
          </div>
          <div class="content">
            <div v-if="index === 4">
              <div class="little">更新日期：<span>2023-05</span></div>
              <div class="little">版本：<span>1.1.8</span></div>
              <div class="little">更新内容：</div>
              <div class="content-update-pc">
                <ul>
                  <li>1、更新企业和个人实名认证上传管理功能</li>
                  <li>2、下线调整抖音私信功能</li>
                </ul>
                <!-- <div class="left">
                  <ul>
                    <li>1、更新企业和个人实名认证上传管理功能</li>
                    <li>2、下线调整抖音私信功能</li>
                  </ul>
                </div> -->
                <!-- <div class="right">
                  <ul>
                    <li>2、下线调整抖音私信功能</li>
                  </ul>
                </div> -->
              </div>
              <div class="content-update-app">
                <ul>
                  <li>1、更新企业和个人实名认证上传管理功能</li>
                  <li>2、下线调整抖音私信功能</li>
                </ul>
              </div>
            </div>
            <div v-if="index === 3">
              <div class="little">更新日期：<span>2023-07</span></div>
              <div class="little">版本：<span>1.1.8</span></div>
              <div class="little">更新内容：</div>
              <div class="content-update-pc">
                <ul>
                  <li>1、修复安卓APP已知问题</li>
                  <li>2、修复ios端已知问题</li>
                  <li>3、上线MAC测试版</li>
                </ul>
                <!-- <div class="left">
                  <ul>
                    <li>1、修复安卓APP已知问题</li>
                    <li>2、修复ios端已知问题</li>
                  </ul>
                </div>
                <div class="right">
                  <ul>
                    <li>3、上线MAC测试版</li>
                  </ul>
                </div> -->
              </div>
              <div class="content-update-app">
                <ul>
                  <li>1、修复安卓APP已知问题</li>
                  <li>2、修复ios端已知问题</li>
                  <li>3、上线MAC测试版</li>
                </ul>
              </div>
            </div>
            <!-- <div v-if="index === 1">
              <div class="little">更新日期：<span>2023-07</span></div>
              <div class="little">版本：<span>1.0.7</span></div>
              <div class="little">更新内容：</div>
              <div class="content-update-pc">
                <div class="left">
                  <ul>
                    <li>1、修复安卓APP已知问题</li>
                    <li>2、修复ios端已知问题</li>
                  </ul>
                </div>
                <div class="right">
                  <ul>
                    <li>3、上线MAC测试版</li>
                  </ul>
                </div>
              </div>
              <div class="content-update-app">
                <ul>
                  <li>1、修复安卓APP已知问题</li>
                  <li>2、修复ios端已知问题</li>
                  <li>3、上线MAC测试版</li>
                </ul>
              </div>
            </div> -->
            <div v-if="index === 2">
              <div class="little">更新日期：<span>2023-10</span></div>
              <div class="little">版本：<span>1.1.8</span></div>
              <div class="little">更新内容：</div>
              <div class="content-update-pc">
                <ul>
                  <li>1、完善关键词品控体系</li>
                  <li>2、完善视频审核模块</li>
                </ul>
                <!-- <div class="left">
                  <ul>
                    <li>1、完善关键词品控体系</li>
                  </ul>
                </div> -->
                <!-- <div class="right">
                  <ul>
                    <li>2、完善视频审核模块</li>
                  </ul>
                </div> -->
              </div>
              <div class="content-update-app">
                <ul>
                  <li>1、完善关键词品控体系</li>
                  <li>2、完善视频审核模块</li>
                </ul>
              </div>
            </div>
            <div v-if="index === 1">
              <div class="little">更新日期：<span>2023-11</span></div>
              <div class="little">版本：<span>1.1.8</span></div>
              <div class="little">更新内容：</div>
              <div class="content-update-pc">
                <ul>
                  <li>1、新版官网上线</li>
                  <li>2、修复服务器漏洞bug</li>
                  <li>3、完善认证体系功能模块</li>
                </ul>
                <!-- <div class="left">
                  <ul>
                    <li>1、新版官网上线</li>
                    <li>2、修复服务器漏洞bug</li>
                  </ul>
                </div> -->
                <!-- <div class="right">
                  <ul>
                    <li>3、完善认证体系功能模块</li>
                  </ul>
                </div> -->
              </div>
              <div class="content-update-app">
                <ul>
                  <li>1、新版官网上线</li>
                  <li>2、修复服务器漏洞bug</li>
                  <li>3、完善认证体系功能模块</li>
                </ul>
              </div>
            </div>
            <div v-if="index === 0">
              <div class="little">更新日期：<span>2023-12</span></div>
              <div class="little">版本：<span>1.1.9</span></div>
              <div class="little">更新内容：</div>
              <div class="content-update-pc">
                <ul>
                  <li>1、已读未读功能更新</li>
                  <li>2、多站点管理功能更新</li>
                  <li>3、修复更新部分API</li>
                  <li>4、管理端IP解锁功能</li>
                  <li>5、完善修复其他已知问题</li>
                </ul>
                <!-- <div class="left">
                  <ul>
                    <li>1、已读未读功能更新</li>
                    <li>2、多站点管理功能更新</li>
                    <li>3、修复更新部分API</li>
                  </ul>
                </div> -->
                <!-- <div class="right">
                  <ul>
                    <li>4、管理端IP解锁功能</li>
                    <li>5、完善修复其他已知问题</li>
                  </ul>
                </div> -->
              </div>
              <div class="content-update-app">
                <ul>
                  <li>1、已读未读功能更新</li>
                  <li>2、多站点管理功能更新</li>
                  <li>3、修复更新部分API</li>
                  <li>4、管理端IP解锁功能</li>
                  <li>5、完善修复其他已知问题</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 试用 -->
    <div class="bottom">
      <on-trial-vue />
    </div>
  </div>
</template>

<script>
import OnTrialVue from "@/components/OnTrial.vue";
export default {
  metaInfo: {
    title: "更新日志 | 小美客服系统",
    meta: [
      {
        name: "keyWords",
        content:
          "在线客服,工单系统，多渠道客服软件系统，大模型智能客服系统，AI在线客服，大模型智能客服，客服软件，智能客服系统，智能客服软件，智能机器人，AI客服系统，工单系统，在线客服系统，在线客服软件，ai机器人，网站客服软件，网站客服系统，小美客服系统，小美客服软件，小美客服机器人，小美智能客服，小美客服平台，防劫持客服系统，抗攻击客服系统，防劫持客服软件，抗攻击客服软件",
      },
      {
        name: "description",
        content:
          "小美客服系统是通过多种渠道提供即时、高效和个性化的服务。我们系统利用人工智能、大数据分析和机器学习等技术，实现智能分流、自动回复和问题识别等功能，大大提升客户服务效率与满意度。同时，我们的客服系统还能收集客户反馈，帮助企业优化产品和服务，增强竞争力。",
      },
    ],
  },
  components: {
    OnTrialVue,
  },
  data() {
    return {
      leftList: [
        { label: "2023-12" },
        { label: "2023-11" },
        { label: "2023-10" },
        { label: "2023-07" },
        { label: "2023-05" },
      ],
      index: 0,
    };
  },
  methods: {
    menuItemClick(val) {
      this.index = val;
    },
  },
};
</script>
<style scoped lang="less">
.content-update-app {
  display: none;

  @media screen and (max-width: 700px) {
    display: block;
    border: 1px solid #304fae;
    padding: 20px;
    border-radius: 12px;
  }
}

.content-update-app ul li {
  @media screen and (max-width: 700px) {
    list-style: none;
    color: #304fae;
    font-size: 15px;
    margin: 10px 0;
  }
}

.question {
  height: 1280px;

  @media screen and (max-width: 700px) {
    height: 1162px;
  }
}

.bottom {
  margin-top: 688px;

  @media screen and (max-width: 700px) {
    margin-top: 530px;
  }
}

.title {
  font-size: 27px;
  color: #304fae;
  font-weight: 600;
  /* padding-left: 10px; */
}

.top {
  width: 100%;
  height: 432px;
  background: -webkit-linear-gradient(left, #00269a, #020c2f);

  @media screen and (max-width: 700px) {
    padding-bottom: 20px;
  }
}

.meun {
  background-color: #304fae;
}

.end {
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
}

.end-box {
  width: 1200px;
  height: 706px;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  display: flex;

  @media screen and (max-width: 700px) {
    width: 100%;
    margin: 10px;
    height: 100%;
  }
}

/*  */
::v-deep .el-menu {
  /* margin: 5px; */
  border: none;

  padding-left: 0px;
}

::v-deep .el-menu-item {
  height: 70px;
  color: #fff;
  line-height: 70px;
  font-size: 20px;

  @media screen and (max-width: 700px) {
    font-size: 15px;
  }
}

::v-deep .el-menu-item.is-active {
  background-color: #fff !important;
  color: #020f3b;
}

::v-deep .el-menu-item:hover {
  background-color: #fff !important;
  color: #020f3b;
}

.content {
  width: 960px;
  height: 100%;
  padding: 60px 100px 40px 100px;

  @media screen and (max-width: 700px) {
    width: 100%;
    padding: 40px 40px;
  }
}

/* .content-title {
    color: #021B64;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 20px;
} */

.little {
  color: #021b64;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 30px;

  @media screen and (max-width: 700px) {
    font-size: 18px;
  }
}

.little span {
  color: #021b64;
  font-size: 22px;
  font-weight: 500;

  @media screen and (max-width: 700px) {
    font-size: 18px;
  }
}

.margin-title {
  margin-top: 15px;
}

.content-text {
  color: #021b64;
  font-size: 18px;
  opacity: 70%;

  @media screen and (max-width: 700px) {
    font-size: 14px;
  }
}

.question-title {
  color: #fff;
  font-size: 40px;
  text-align: center;
  padding: 180px 0 90px 0;
  font-weight: 500;
}

.content-update-pc {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  border: 1px solid #304fae;
  opacity: 0.7;
  display: flex;

  @media screen and (max-width: 700px) {
    display: none;
  }
}

.content-update-pc ul li {
  list-style: none;
  color: #304fae;
  font-size: 22px;
  margin: 30px;
}

.left {
  border-right: 1px solid #304fae;
  width: 50%;

  padding: 20px;
}

// .right {
//   width: 50%;
//   padding: 20px;
// }
</style>
