<template>
  <div class="question">
    <div class="end">
      <div class="end-box">
        <Content></Content>
        <div class="meun">
          <el-menu
            default-active="0"
            background-color="#fff"
            text-color="#333"
            active-text-color="#fff"
          >
            <el-menu-item
              @click="menuItemClick(index)"
              v-for="(list, index) in leftList"
              :key="index"
              :index="index.toString()"
            >
              <span slot="title">{{ list.label }}</span>
            </el-menu-item>
          </el-menu>
        </div>
        <div class="content">
          <div v-if="index === 0">
            <div class="content-title">隐私声明</div>
            <div class="little">标题一</div>
            <div class="content-text">
              这几天，新冠疫情在多地散发。仔细看各地的通报会发现，口岸城市内蒙古满洲里的新增确诊病例仍然比较多，本轮疫情的累计确诊人数已经达到400多例。其它地方，有多个确诊病例都呈现出跨地、跨省接触感染的情况。为此，上海和南京的疾控部门已经提示，非必要不离开本地。上海市卫健委还特别提醒各有关单位，元旦春节期间尽量不要组织年会、节庆等人员聚集活动。
            </div>
            <div class="little margin-title">标题二</div>
            <div class="content-text">
              从病例散发的情况来看，当前我国疫情防控的重点无疑是守住国门、外防输入。同时也要倡导群众合理出行，严防因输入导致国内疫情反弹。到底怎么做呢？简单来说有一个关键词：坚持。根据国务院联防联控机制的部署，要继续坚持“外防输入、内防反弹”的总策略、“动态清零”的总方针，这是我国控制疫情的“法宝”。这一套打法，以最小的成本取得了最大成效，有力保障了大家的生命健康和经济社会发展。正确的做法、好的办法，就要坚持。对个人来说，怎么做相信大家都已经很清楚了，现在需要的也是坚持。坚持能出战疫成效，坚持就是胜利。
              这几天，新冠疫情在多地散发。仔细看各地的通报会发现，口岸城市内蒙古满洲里的新增确诊病例仍然比较多，本轮疫情的累计确诊人数已经达到400多例。其它地方，有多个确诊病例都呈现出跨地、跨省接触感染的情况。为此，上海和南京的疾控部门已经提示，非必要不离开本地。上海市卫健委还特别提醒各有关单位，元旦春节期间尽量不要组织年会、节庆等人员聚集活动。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 试用 -->
    <div class="bottom">
      <on-trial-vue />
    </div>
  </div>
</template>

<script>
import OnTrialVue from "@/components/OnTrial.vue";
import Content from "./content.vue";
export default {
  metaInfo: {
    title: "隐私声明 | 小美客服系统",
    meta: [
      {
        name: "keyWords",
        content:
          "在线客服,工单系统，多渠道客服软件系统，大模型智能客服系统，AI在线客服，大模型智能客服，客服软件，智能客服系统，智能客服软件，智能机器人，AI客服系统，工单系统，在线客服系统，在线客服软件，ai机器人，网站客服软件，网站客服系统，小美客服系统，小美客服软件，小美客服机器人，小美智能客服，小美客服平台，防劫持客服系统，抗攻击客服系统，防劫持客服软件，抗攻击客服软件",
      },
      {
        name: "description",
        content:
          "小美客服系统是通过多种渠道提供即时、高效和个性化的服务。我们系统利用人工智能、大数据分析和机器学习等技术，实现智能分流、自动回复和问题识别等功能，大大提升客户服务效率与满意度。同时，我们的客服系统还能收集客户反馈，帮助企业优化产品和服务，增强竞争力。",
      },
    ],
  },
  components: {
    OnTrialVue,
    Content,
  },
  // data() {
  //   return {
  //     leftList: [{ label: "隐私声明" }, { label: "终止协议" }],
  //     index: 0,
  //   };
  // },
  methods: {
    menuItemClick(val) {
      this.index = val;
    },
  },
};
</script>
<style scoped lang="less">
.question {
  background-color: #f4f7ff;
  padding-top: 80px;
}

.end {
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
}

.end-box {
  width: 1400px;
  height: 706px;
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  display: flex;

  @media screen and (max-width: 700px) {
    width: 100%;
    margin: 10px;
    height: 100%;
  }
}
</style>
