<template>
  <div>
    <!-- 背景 -->
    <div class="workImg">
      <!-- <img src="@/assets/work/workOrder.png" /> -->
      <!-- 背景文字 -->
      <div class="customer-title">
        <h1 class="customer-big-title">工单系统</h1>
        <h2 class="customer-little-title">根据您的习惯和需要定制客服软件</h2>
        <h2 class="customer-little-title br">
          我们用最新的技术来确保您和客户之间保持顺畅的沟通体验
        </h2>
      </div>
    </div>
    <!-- 为您 -->
    <div class="center-two">
      <div class="center-two-top">
        <div class="center-two-title">为您的企业提高交易转化率</div>
        <div class="center-two-little-title">
          极简的使用体验，尊贵的服务态度，我们100%竭诚为您提供最需要的功能
        </div>
      </div>
      <div class="work-card">
        <work-vue></work-vue>
      </div>
    </div>
    <!-- 建立 -->
    <div class="center-two">
      <div class="center-two-top">
        <div class="center-two-title">建立标准化服务流程</div>
      </div>
      <div class="work-img">
        <img src="@/assets/work/workBigImg.png" alt="服务流程" />
      </div>
    </div>
    <!-- 功能介绍 -->
    <div class="center-two">
      <div class="center-two-top">
        <div class="center-two-title">功能介绍</div>
      </div>
      <!--  -->
      <div class="work-main">
        <div class="work-left">
          <div class="work-main-title">自动流转</div>
          <div class="work-main-content">
            无论用户来自哪个渠道的事情，都能用一张工单记录业务的详情，客服可根据用户企业业务<br />需求创建工单，将工单流转给配合相关部门的负责人。
          </div>
        </div>
        <div class="work-main-img">
          <!-- <img class="img-top" src="@/assets/work/turnaround.png" /> -->
          <!-- <img class="img-end" src="@/assets/work/workLittleImg.png" /> -->
          <img class="img" src="@/assets/work/turnaround.png" alt="自动流转" />
        </div>
      </div>
      <!--  -->
      <div class="work-main">
        <div class="work-main-img">
          <!-- <img class="img-top" src="@/assets/work/remind.png" /> -->
          <!-- <img class="img-end" src="@/assets/work/workLittleImg.png" /> -->
          <img
            style="margin-left: -207px"
            class="img"
            src="@/assets/work/remind.png"
            alt="实时提醒"
          />
        </div>
        <div class="work-left">
          <div class="work-main-title">实时提醒</div>
          <div class="work-main-content">
            提供工单受理人的电话、QQ、邮件提醒服务，当有新工单时会收到提醒，及时参与协作
            <br />和用户沟通，保证问题解决。
          </div>
        </div>
      </div>
      <!--  -->
      <div class="work-main">
        <div class="work-left">
          <div class="work-main-title">自动分配</div>
          <div class="work-main-content">
            工单分配能够根据公司业务进行个性化业务定制，自动将工单分配给指定部门或者员工，让
            合适的人做合适的事。
          </div>
        </div>
        <div class="work-main-img">
          <!-- <img class="img-top" src="@/assets/work/distribution.png" /> -->
          <!-- <img class="img-end" src="@/assets/work/workLittleImg.png" /> -->
          <img
            class="img"
            src="@/assets/work/distribution.png"
            alt="自动分配"
          />
        </div>
      </div>
    </div>
    <!-- 多企业 -->
    <div class="end">
      <multienter-prise-vue />
    </div>

    <!-- 试用 -->
    <div>
      <on-trial-vue />
    </div>
  </div>
</template>

<script>
import WorkVue from "./work.vue";
import OnTrialVue from "@/components/OnTrial.vue";
import MultienterPriseVue from "@/components/MultienterPrise.vue";
export default {
  metaInfo: {
    title: "工单系统 | 小美客服系统",
    meta: [
      {
        name: "keyWords",
        content:
          "在线客服,工单系统，多渠道客服软件系统，大模型智能客服系统，AI在线客服，大模型智能客服，客服软件，智能客服系统，智能客服软件，智能机器人，AI客服系统，工单系统，在线客服系统，在线客服软件，ai机器人，网站客服软件，网站客服系统，小美客服系统，小美客服软件，小美客服机器人，小美智能客服，小美客服平台，防劫持客服系统，抗攻击客服系统，防劫持客服软件，抗攻击客服软件",
      },
      {
        name: "description",
        content:
          "小美客服系统是通过多种渠道提供即时、高效和个性化的服务。我们系统利用人工智能、大数据分析和机器学习等技术，实现智能分流、自动回复和问题识别等功能，大大提升客户服务效率与满意度。同时，我们的客服系统还能收集客户反馈，帮助企业优化产品和服务，增强竞争力。",
      },
    ],
  },
  components: {
    WorkVue,
    OnTrialVue,
    MultienterPriseVue,
  },
};
</script>
<style scoped lang="less">
.end {
  @media screen and (max-width: 700px) {
    margin-top: 30px;
  }
}

.workImg img {
  width: 100%;
  height: 644px;

  @media screen and (max-width: 700px) {
    height: 184px;
  }
}

.workImg {
  position: relative;
  width: 100%;
  height: 644px;
  background-image: url("@/assets/work/workOrder.png");
  background-size: 105% 100%;

  @media screen and (max-width: 700px) {
    position: relative;
    width: 100%;
    height: 200px;
    background-image: url("@/assets/work/workOrder.png");
    background-size: 105% 100%;
  }
}

.work-left {
  /* background-color: red; */
  width: 850px;

  @media screen and (max-width: 700px) {
  }
}

.customer-title {
  position: absolute;
  top: 35%;
  left: 11%;

  @media screen and (max-width: 700px) {
    top: 35%;
    left: 10%;
  }
}

.customer-big-title {
  color: #fff;
  font-size: 52px;
  margin-bottom: 16px;
  font-weight: 500;

  @media screen and (max-width: 700px) {
    font-size: 25px;
    margin-bottom: 20px;
  }
}

.br {
  margin-top: 5px;

  @media screen and (max-width: 700px) {
  }
}

.customer-little-title {
  color: #fff;
  opacity: 80%;
  font-size: 17px;
  font-weight: 400;

  @media screen and (max-width: 700px) {
    // font-size: 15px;
    display: none;
  }
}

.center-two-title {
  color: #020c30;
  font-size: 38px;
  padding-bottom: 10px;

  @media screen and (max-width: 700px) {
    font-size: 25px;
  }
}

.center-two-little-title {
  color: #020c30;
  font-size: 20px;
  opacity: 60%;

  @media screen and (max-width: 700px) {
    font-size: 14px;
  }
}

.center-two {
  padding-top: 20px;
  text-align: center;
  background-color: #f4f7ff;

  @media screen and (max-width: 700px) {
  }
}

.img {
  display: inline-block;
  position: relative;
  width: 233px;
  height: 137px;

  @media screen and (max-width: 700px) {
  }
}

.img img {
  width: 173px;
  height: 97px;

  @media screen and (max-width: 700px) {
  }
}

.text {
  display: inline-block;

  @media screen and (max-width: 700px) {
  }
}

.work-card {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 40px;

  @media screen and (max-width: 700px) {
  }
}

.work-img {
  margin: 0 auto;
  width: 100%;
  padding: 60px 0 150px 0;

  @media screen and (max-width: 700px) {
    padding: 60px 0 100px 0;
  }
}

.work-img img {
  width: 1031px;
  height: 552px;

  @media screen and (max-width: 700px) {
    width: 431px;
    height: 222px;
  }
}

.work-main {
  display: flex;
  justify-content: center;
  padding: 70px 0 20px 200px;
  position: relative;

  @media screen and (max-width: 700px) {
    align-items: center;
    // flex-direction: column;
    padding: 40px;
  }
}

.work-main-title {
  color: #021b64ff;
  font-weight: 600;
  font-size: 22px;
  text-align: start;
  padding: 15px 0;

  @media screen and (max-width: 700px) {
    text-align: center;
  }
}

.work-main-content {
  color: #021b64ff;
  opacity: 70%;
  font-size: 18px;
  text-align: start;

  @media screen and (max-width: 700px) {
  }
}

.work-main-img img {
  width: 440px;
  height: 360px;

  @media screen and (max-width: 700px) {
  }
}

.work-main-img {
  position: relative;
  width: 540px;
  height: 173px;
  top: -105px;
  @media screen and (max-width: 700px) {
    display: none;
  }
}

.img-top {
  position: absolute;
  top: 0px;
  left: 115px;
  z-index: 2;

  @media screen and (max-width: 700px) {
  }
}

.img-end {
  position: absolute;
  top: -70px;
  left: 0px;
  z-index: 1;

  @media screen and (max-width: 700px) {
  }
}

.img-top-text {
  position: absolute;
  bottom: 40px;

  @media screen and (max-width: 700px) {
  }
}
</style>
