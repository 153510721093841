<template>
  <div class="question">
    <div class="end">
      <div class="end-box">
        <Content></Content>
      </div>
    </div>
    <!-- 试用 -->
    <div class="bottom">
      <on-trial-vue />
    </div>
  </div>
</template>

<script>
import OnTrialVue from "@/components/OnTrial.vue";
import Content from "./content.vue";
export default {
  metaInfo: {
    title: "用户协议 | 小美客服系统",
    meta: [
      {
        name: "keyWords",
        content:
          "在线客服,工单系统，多渠道客服软件系统，大模型智能客服系统，AI在线客服，大模型智能客服，客服软件，智能客服系统，智能客服软件，智能机器人，AI客服系统，工单系统，在线客服系统，在线客服软件，ai机器人，网站客服软件，网站客服系统，小美客服系统，小美客服软件，小美客服机器人，小美智能客服，小美客服平台，防劫持客服系统，抗攻击客服系统，防劫持客服软件，抗攻击客服软件",
      },
      {
        name: "description",
        content:
          "小美客服系统是通过多种渠道提供即时、高效和个性化的服务。我们系统利用人工智能、大数据分析和机器学习等技术，实现智能分流、自动回复和问题识别等功能，大大提升客户服务效率与满意度。同时，我们的客服系统还能收集客户反馈，帮助企业优化产品和服务，增强竞争力。",
      },
    ],
  },
  components: {
    OnTrialVue,
    Content,
  },
};
</script>
<style scoped lang="less">
.question {
  background-color: #f4f7ff;
  padding-top: 80px;
}

.end {
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
}

.end-box {
  width: 1400px;
  height: 706px;
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  display: flex;

  @media screen and (max-width: 700px) {
    width: 100%;
    margin: 10px;
    height: 100%;
  }
}
</style>
