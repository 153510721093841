<template>
  <div>
    <div class="top">
      <img src="@/assets/introduce/i1.png" />
      <div class="top-box">
        <h1 class="title">行情资讯</h1>
        <div class="content">
          <div class="list">
            <div class="left"></div>
            <div class="right">
              <div>抗击疫情，我们在路上</div>
              <p>
                这几天，新冠疫情在多地散发。仔细看各地的通报会发现，口岸城市内蒙古满洲里的新增确诊病例仍然比较多，本轮疫情的累计确诊人数已经达到400多例。其它地方，有多个确诊病例都呈现出跨地、跨省接触感染的情况。
              </p>
            </div>
          </div>
          <div class="list">
            <div class="left"></div>
            <div class="right">
              <div>抗击疫情，我们在路上</div>
              <p>
                这几天，新冠疫情在多地散发。仔细看各地的通报会发现，口岸城市内蒙古满洲里的新增确诊病例仍然比较多，本轮疫情的累计确诊人数已经达到400多例。其它地方，有多个确诊病例都呈现出跨地、跨省接触感染的情况。
              </p>
            </div>
          </div>
          <div class="list">
            <div class="left"></div>
            <div class="right">
              <div>抗击疫情，我们在路上</div>
              <p>
                这几天，新冠疫情在多地散发。仔细看各地的通报会发现，口岸城市内蒙古满洲里的新增确诊病例仍然比较多，本轮疫情的累计确诊人数已经达到400多例。其它地方，有多个确诊病例都呈现出跨地、跨省接触感染的情况。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "行情资讯 | 小美客服系统",
    meta: [
      {
        name: "keyWords",
        content:
          "在线客服,工单系统，多渠道客服软件系统，大模型智能客服系统，AI在线客服，大模型智能客服，客服软件，智能客服系统，智能客服软件，智能机器人，AI客服系统，工单系统，在线客服系统，在线客服软件，ai机器人，网站客服软件，网站客服系统，小美客服系统，小美客服软件，小美客服机器人，小美智能客服，小美客服平台，防劫持客服系统，抗攻击客服系统，防劫持客服软件，抗攻击客服软件",
      },
      {
        name: "description",
        content:
          "小美客服系统是通过多种渠道提供即时、高效和个性化的服务。我们系统利用人工智能、大数据分析和机器学习等技术，实现智能分流、自动回复和问题识别等功能，大大提升客户服务效率与满意度。同时，我们的客服系统还能收集客户反馈，帮助企业优化产品和服务，增强竞争力。",
      },
    ],
  },
};
</script>
<style scoped lang="less">
.top {
  height: 1251px;
  width: 100%;
  position: relative;
}

.top img {
  width: 100%;
  height: 432px;

  @media screen and (max-width: 700px) {
    height: 200px;
  }
}

.top-box {
  width: 1200px;
  height: 915px;
  background-color: #fff;
  position: absolute;
  top: 216px;
  left: 50%;
  margin-left: -600px;

  @media screen and (max-width: 700px) {
    top: 270px;
    width: 400px;
    margin-left: -200px;
  }
}

.title {
  color: #021b64;
  font-size: 34px;
  text-align: center;
  margin: 40px 0;
  font-weight: 500;
  @media screen and (max-width: 700px) {
  }
}

.left {
  width: 302px;
  height: 170px;
  background-color: rgba(2, 15, 59, 0.09);
  border-radius: 10px;
  margin-right: 20px;
  @media screen and (max-width: 700px) {
    width: 122px;
    height: 100px;
    margin-right: 10px;
  }
}

.right {
  width: 718px;
  margin-left: 20px;
  @media screen and (max-width: 700px) {
    width: 208px;
    margin-left: 10px;
  }
}

.right div {
  color: #021b64;
  font-size: 26px;
  margin-bottom: 10px;
  @media screen and (max-width: 700px) {
    font-size: 20px;
  }
}

.right p {
  font-size: 18px;
  color: #021b64;
  opacity: 0.7;
  @media screen and (max-width: 700px) {
    font-size: 14px;
  }
}

.list {
  width: 86%;
  margin: 50px 80px;
  border-bottom: 1px solid rgba(2, 15, 59, 0.09);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
  @media screen and (max-width: 700px) {
    margin: 50px 30px;
  }
}
</style>
