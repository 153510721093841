<template>
  <div class="price">
    <div class="price-top"></div>
    <div class="position">
      <div class="pc">
        <price-part-vue />
      </div>
      <div class="app">
        <price-part-app-vue />
      </div>
    </div>
  </div>
</template>

<script>
import PricePartVue from "./PricePart.vue";
import PricePartAppVue from "./PricePartApp.vue";
export default {
  metaInfo: {
    title: "价格 | 小美客服系统",
    meta: [
      {
        name: "keyWords",
        content:
          "在线客服,工单系统，多渠道客服软件系统，大模型智能客服系统，AI在线客服，大模型智能客服，客服软件，智能客服系统，智能客服软件，智能机器人，AI客服系统，工单系统，在线客服系统，在线客服软件，ai机器人，网站客服软件，网站客服系统，小美客服系统，小美客服软件，小美客服机器人，小美智能客服，小美客服平台，防劫持客服系统，抗攻击客服系统，防劫持客服软件，抗攻击客服软件",
      },
      {
        name: "description",
        content:
          "小美客服系统是通过多种渠道提供即时、高效和个性化的服务。我们系统利用人工智能、大数据分析和机器学习等技术，实现智能分流、自动回复和问题识别等功能，大大提升客户服务效率与满意度。同时，我们的客服系统还能收集客户反馈，帮助企业优化产品和服务，增强竞争力。",
      },
    ],
  },
  components: {
    PricePartVue,
    PricePartAppVue,
  },
};
</script>
<style scoped lang="less">
.pc {
  display: flex;
  height: 1174px;
  border-radius: 5px;
  justify-content: space-around;
  align-items: flex-end;

  @media screen and (max-width: 700px) {
    display: none;
  }
}

.app {
  display: none;

  @media screen and (max-width: 700px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    // height: 1174px;
    // border-radius: 5px;
    justify-content: center;
    // align-items: flex-end;
  }
}

.price {
  position: relative;
  height: 1344px;
  padding-bottom: 40px;

  @media screen and (max-width: 700px) {
    position: relative;
    height: 4900px;
    padding-bottom: 0px;
  }
}

.position {
  position: absolute;
  top: 168px;
  left: 50%;
  margin-left: -640px;

  @media screen and (max-width: 700px) {
    position: absolute;
    top: 168px;
    left: 50%;
    margin-left: -160px;
  }
}

.price-top {
  width: 100%;
  height: 100%;
  // background: -webkit-linear-gradient(left, #00269a, #020c2f);

  background-image: url("@/assets/price/base.png");
  background-size: 100% 100%;
  @media screen and (max-width: 700px) {
  }
}
</style>
