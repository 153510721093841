<!-- 
 * @description: 修改了mac、windows的下载logo
 * @date: 
 * @author: 程
 * @LastEditors: 郭晴
 * @LastEditTime: 2023-12-29 19:21:15 
 * @FilePath:  
 * @version: V1.0.0 
!-->
<template>
  <div class="question">
    <div class="top">
      <h1 class="question-title">下载中心</h1>
      <div class="end">
        <div class="end-box">
          <div class="box">
            <img src="@/assets/center/center4.png" alt="Android端工作台" />
            <div class="box-title">Android端工作台</div>
            <p class="box-text">版本号：1.0.7</p>
            <p class="box-text">更新时间：2023-03-08</p>
            <button>点击下载</button>
          </div>
          <div class="box">
            <img src="@/assets/center/center2.png" alt="iOS端工作台" />
            <div class="box-title">iOS端工作台</div>
            <p class="box-text">版本号：1.0.7</p>
            <p class="box-text">更新时间：2023-04-17</p>
            <button>点击下载</button>
          </div>
          <div class="box">
            <img src="@/assets/center/mac.png" alt="Mac系统" />
            <div class="box-title">Mac系统</div>
            <p class="box-text">版本号：1.1.8</p>
            <p class="box-text">更新时间：2023-06-11</p>
            <button>点击下载</button>
          </div>
          <div class="box">
            <img src="@/assets/center/windows.png" alt="Windows系统" />
            <div class="box-title">Windows系统</div>
            <p class="box-text">版本号：1.1.9</p>
            <p class="box-text">更新时间：2023-12-29</p>
            <button @click="windowsInstall">点击下载</button>
          </div>
        </div>
      </div>
    </div>
    <!-- 试用 -->
    <div class="bottom">
      <on-trial-vue />
    </div>
  </div>
</template>

<script>
import OnTrialVue from "@/components/OnTrial.vue";
export default {
  components: {
    OnTrialVue,
  },
  metaInfo: {
    title: "下载中心 | 小美客服系统",
    meta: [
      {
        name: "keyWords",
        content:
          "在线客服,工单系统，多渠道客服软件系统，大模型智能客服系统，AI在线客服，大模型智能客服，客服软件，智能客服系统，智能客服软件，智能机器人，AI客服系统，工单系统，在线客服系统，在线客服软件，ai机器人，网站客服软件，网站客服系统，小美客服系统，小美客服软件，小美客服机器人，小美智能客服，小美客服平台，防劫持客服系统，抗攻击客服系统，防劫持客服软件，抗攻击客服软件",
      },
      {
        name: "description",
        content:
          "小美客服系统是通过多种渠道提供即时、高效和个性化的服务。我们系统利用人工智能、大数据分析和机器学习等技术，实现智能分流、自动回复和问题识别等功能，大大提升客户服务效率与满意度。同时，我们的客服系统还能收集客户反馈，帮助企业优化产品和服务，增强竞争力。",
      },
    ],
  },
  methods: {
    windowsInstall() {
      var filename = "a.mp4";
      let link = document.createElement("a");
      document.body.appendChild(link);
      link.style.display = "none";
      link.href =
        "https://download.chat5188.com/download/xiaomei_1.1.9_x64.zip";
      // link.href =
      //   "https://download.chat5188.com/download/xiaomei_1.1.8_x64.zip";
      link.download = filename;
      link.click();
      document.body.removeChild(link);
      // window.URL.revokeObjectURL(
      //   "https://download.chat5188.com/download/xiaomei_1.1.8_x64.zip"
      // );
    },
  },
};
</script>
<style scoped lang="less">
.question {
  height: 1280px;

  @media screen and (max-width: 700px) {
    height: 2612px;
  }
}

.bottom {
  margin-top: 688px;

  @media screen and (max-width: 700px) {
    margin-top: 2000px;
  }
}

.question-title {
  color: #fff;
  font-size: 40px;
  text-align: center;
  padding: 180px 0 90px 0;
  font-weight: 500;
}

.top {
  width: 100%;
  height: 432px;
  background: -webkit-linear-gradient(left, #00269a, #020c2f);
}

.end {
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
}

.end-box {
  width: 1230px;
  height: 706px;
  border-radius: 5px;
  overflow: hidden;
  display: flex;

  @media screen and (max-width: 700px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 2300px;
  }
}

.box {
  width: 300px;
  height: 455px;
  background-color: #fff;
  margin: 15px;
  text-align: center;
  padding: 30px;
  border-radius: 6px;

  @media screen and (max-width: 700px) {
    padding: 0px 30px 30px 30px;
  }
}

.box img {
  width: 86px;
  height: 86px;
  margin: 60px 0 50px 0;
}

.box-title {
  font-size: 22px;
  color: #021b64;
  margin: 0 0 30px 0;
}

.box-text {
  font-size: 20px;
  color: #021b64;
  opacity: 0.7;
}

.end-box button {
  color: #fff;
  outline: none;
  background-color: #6dd6ff;
  font-size: 20px;
  border-radius: 8px;
  border: none;
  width: 220px;
  height: 44px;
  margin: 50px 0 0 0;
  cursor: pointer;
}
</style>
